import React from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import blog1 from "../../assets/img/blog1.png";
import blog2 from "../../assets/img/blog2.jpg";
import blog3 from "../../assets/img/blog3.jpg";
import blog4 from "../../assets/img/blog4.jpg";
import blog22 from "../../assets/img/transformingar.png"
import blog11 from "../../assets/img/Anomaly-Detection-Finance.png"
import { useMediaQuery, useTheme } from "@mui/material/";

import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1345 },
    items: 4,
  },
  superdesktop: {
    breakpoint: { max: 1296, min: 1000 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1000, min: 770 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 770, min: 0 },
    items: 1,
  },
};

function Section2(props) {
  return (
    <div
      id="section2-blog-success-story"
      className="blog-success-section2 scroll-section"
    >
      <div className="customer-section2-body margin-box">
        <h1 className="success-story-page-section-2-Header-text">Finance</h1>
        <div className="customer-section2-body">
          <div className="section2-box">
            {/* <div
              style={{
                display: "flex",
                justifyContent: "space-around",
              }}
              className="new-cls-blogs"
            > */}

            <Carousel
              // className="customer-success-main"

              responsive={responsive}
              dotListClass="custom-dot-list-style"
              // itemClass="carousel-item-padding-40-px"
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              infinite={true}
              slidesToSlide={1}
              swipeable={true}
              draggable={false}
              // showDots={isMobile? false : true}
            >
              <CustomerSectionBox
                picture={blog22}
                title1="How AI is Transforming Accounts Receivable Processes"
                date="8-Nov-24"
                read="2 min read"
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/ai-in-accounts-receivable"
              />

              <CustomerSectionBox
                picture={blog11}
                title1="How AI is Transforming Financial Anomaly Detection"
                date="29-Oct-24"
                read="2 min read"
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/financial-anomalies-with-ai"
              />
              <CustomerSectionBox
                picture={blog1}
                title1="Data Analytics Implementation Journey in Business and Finance"
                date="March 31, 2023 "
                read="2 min read"
                class="content-box-section2a"
                redirectURL="/blogs/data-analytics-implementation-journey-in-business-and-finance"
              />
              <CustomerSectionBox
                picture={blog2}
                title1="How is AI transforming FP&A in the consumer goods industry?"
                date="March 3, 2023  "
                read="5 min read "
                class="content-box-section2a"
                redirectURL="/blogs/how-is-ai-transforming-fp-a-in-the-consumer-goods-industry"
              />
              <CustomerSectionBox
                picture={blog3}
                title1="Transforming Finance Function with Advanced Analytics"
                date="January 29, 2023  "
                read=" 2 min read"
                class="content-box-section2a"
                redirectURL="/blogs/transforming-finance-function-with-advanced-analytics"
              />

              <CustomerSectionBox
                picture={blog4}
                title1="How large enterprises are democratizing data science, ML in the corporate finance space"
                date="December 14, 2022  "
                read="2 min read "
                class="content-box-section2a"
                redirectURL="/blogs/how-large-enterprises-are-democratising-data-science-ml-in-the-corporate-finance-space"
              />
            </Carousel>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section2;
