import React, { useState } from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import ai1 from "../../assets/img/Aays_Gen AI Use Cases.square.jpg";
import ai2 from "../../assets/img/Aays_Gen AI Investment Strategies.Square.jpg";
import ai2as from "../../assets/img/AIML-decision-making_Aays.jpg";
import ai4 from "../../assets/img/ai4.jpg"
import ai8 from "../../assets/img/Blog - AI in Marketing.png"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1345 },
    items: 4,
  },
  superdesktop: {
    breakpoint: { max: 1296, min: 1000 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1000, min: 770 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 770, min: 0 },
    items: 1,
  },
};

const AiAndGenerativeAI = () => {
 

  
  

  return (
    <div
      id="aiAndGeneratives"
      className="blog-success-section2 scroll-section"
    >
      <div className="customer-section2-body margin-box">
        <h1 className="success-story-page-section-2-Header-text"> AI and Generative AI</h1>
        <div className="customer-section2-body">
          <div className="section2-box">
           
            <Carousel
              // className="customer-success-main"

              responsive={responsive}
              dotListClass="custom-dot-list-style"
              // itemClass="carousel-item-padding-40-px"
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              infinite={true}
              slidesToSlide={1}
              swipeable={true}
              draggable={false}
              // showDots={isMobile? false : true}
            >
              <CustomerSectionBox
                picture={ai8}
                title1="The Role of AI-Driven Personalization in Modern Marketing"
                date="September 24 ,2024 "
                read="2 min read"
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/ai-driven-personalization-in-marketing"
              />
              <CustomerSectionBox
                picture={ai2as}
                title1="Leveraging AI and ML for Business Decision-Making?"
                date="September 4, 2024  "
                read="5 min read "
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/leveraging-ai-and-ml-for-business-decision-making"
              />
              <CustomerSectionBox
                picture={ai1}
                title1="Prioritizing AI and GenAI Use Cases: A Strategic Quadrant Framework for Enterprise Adoption"
                date="Jul 1, 2024 "
                read=" 2 min read"
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/prioritizing-ai-and-gen-ai-use-cases"
              />

              <CustomerSectionBox
                picture={ai2}
                title1="Why Invest in Generative AI Now? Exploring AI Investment Strategies"
                date="Jul 8, 2024  "
                read="2 min read "
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/why-invest-in-gen-ai-now"
              />
               <CustomerSectionBox
                picture={ai4}
                title1="How is big data and automotive analytics are transforming the automotive industry?"
                date="Feb 16, 2023 "
                read="2 min read "
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/how-is-big-data-and-analytics-helping-the-automotive-industry"
              />
            </Carousel>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiAndGenerativeAI;
