import React from "react";
import CustomerSectionBox from "../../reuseLayout/CustomerSectionBox";
import AmitGirnale from "../../assets/img/AmitGirnale.png";
import DeveshPal from "../../assets/img/DeveshPal.png";
import AbhishekBlogsSquareImage from "../../assets/img/AbhishekBlogsSquareImage.png";
import tools4 from "../../assets/img/Workplace Leader.square.1.jpg";
import saurbhjain from "../../assets/img/saurabhjain.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1345 },
    items: 4,
   
  },
  superdesktop: {
    breakpoint: { max: 1345, min: 1000 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1000, min: 770 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 770, min: 0 },
    items: 1,
  },
};


function  LifeAays() {
  return (
    <div
      id="lifeaays"
      className="blog-success-section2 scroll-section"
    >
      <div className="customer-section2-body margin-box">
        <div className="success-story-page-section-2-Header-text">
        Life at Aays
        </div>
        <div className="customer-section2-body">
          <div className="section2-box">
           

            <Carousel
              responsive={responsive}
              dotListClass="custom-dot-list-style"
             
              arrows={false}
              autoPlay={true}
              autoPlaySpeed={3000}
              keyBoardControl={true}
              infinite={true}
              slidesToSlide={1}
              swipeable={true}
              draggable={false}
            >
               <CustomerSectionBox
                picture={saurbhjain}
                title1="Data Analyst Career Insights: Interview with Saurabh Jain, Senior Data Analyst"
                date="Nov 6, 2024 "
                read="2 min read"
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/data-analyst-career-insights-interview-with-saurabh-jain-senior-data-analyst"
              />
              <CustomerSectionBox
                picture={AmitGirnale}
                title1="Empowered to Learn and Grow: Amit Girnale's Data Analytics Career Growth at Aays"
                date="Oct 24, 2024 "
                read="2 min read"
                class="content-box-section2a"
                redirectURL="https://blog.aaysanalytics.com/post/amit-girnale-data-analytics-career-growth"
              />
              <CustomerSectionBox
                picture={DeveshPal}
                title1="Devesh Pal's Journey in Data Engineering at Aays"
                date="Oct 16, 2024"
                class="content-box-section2a"
                read=" 2 min read"
                redirectURL="https://blog.aaysanalytics.com/post/data-engineering-at-aays-devesh-pal-interview"
              />
              <CustomerSectionBox
                picture={tools4}
                title1="Aays Recognized as One of the 50 Best Firms for Data Scientists to Work for in 2024"
                date="May 26, 2024"
                class="content-box-section2a"
                read="2 min read"
                redirectURL="https://blog.aaysanalytics.com/post/50-best-firms-for-data-scientists-2024"
              />

              <CustomerSectionBox
                picture={AbhishekBlogsSquareImage}
                
                title1="Aays is now Great Place to Work Certified"
                date="Aug 30, 2024"
                class="content-box-section2a"
                read="2 min read"
                redirectURL="https://blog.aaysanalytics.com/post/aays-great-place-to-work-certified"
              />
            </Carousel>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default  LifeAays;
